.checkList {
    font-family: 'Work Sans' !important;
}

* {
    font-family: 'Work Sans' !important;
}

.mainPage {
    background-image: linear-gradient(to right, yellow, red) !important;
}

.appheader1 {
    background-image: linear-gradient(to right, yellow, red) !important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.label2 {
    font-size: 14px !important;
}